var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"title":_vm.product.name,"back-route":{ name: 'Shop' }}}),(_vm.loading)?_c('div',{staticClass:"tw-absolute tw-h-10 tw-top-1/2 tw-left-1/2"},[_c('i',{staticClass:"fal fa-spinner-third fa-spin tw-text-3xl"})]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"tw-my-8 tw-pb-16"},[_c('h2',[_vm._v("Genereer een nieuw visitekaartje")]),(!_vm.product.is_business_card)?_c('div',{staticClass:"tw-text-error"},[_vm._v(" Dit product is geen visitekaartje. ")]):_vm._e(),_c('div',{staticClass:"tw-grid md:tw-grid-cols-2 tw-gap-4"},[_c('FormulateForm',{attrs:{"name":"businessCard","invalid-message":"Gelieve de verplichte velden correct in te vullen."},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasErrors = ref.hasErrors;
return [_c('FormulateInput',{attrs:{"type":"select","name":"languages","label":"Taal","placeholder":"Selecteer taal","options":_vm.USER_LANG_OPTIONS,"value":"nl","validation":"required"}}),(_vm.contactNumber)?_c('FormulateInput',{attrs:{"type":"radio","name":"phone_number","label":"Selecteer contactnummer","options":_vm.contactNumber.options,"value":_vm.contactNumber.value}}):_vm._e(),(_vm.officeOptions.length)?_c('FormulateInput',{attrs:{"type":"checkbox","name":"offices","label":"Selecteer kantoren","options":_vm.officeOptions}}):_vm._e(),_c('FormulateErrors'),_c('div',{staticClass:"tw-flex tw-flex-wrap tw-gap-2"},[_c('FormulateInput',{attrs:{"type":"submit","title":"Voorbeeld","disabled":_vm.generatingPreview || _vm.generatingBusinessCard,"input-class":['tw-w-full'],"outer-class":"tw-m-0 tw-flex-grow"},on:{"click":function($event){return _vm.submit(true, hasErrors)}}},[_c('i',{class:[
                'fas tw-mr-1',
                _vm.generatingPreview ? 'fa-spinner-third fa-spin' : 'fa-eye'
              ]}),_vm._v(" Voorbeeld ")]),(_vm.businessCardPreviewed)?_c('FormulateInput',{attrs:{"type":"submit","title":"Maak visitekaartje","disabled":_vm.generatingPreview || _vm.generatingBusinessCard,"input-class":['tw-bg-primary tw-w-full'],"outer-class":"tw-m-0 tw-flex-grow"},on:{"click":function($event){return _vm.submit(false, hasErrors)}}},[_c('i',{class:[
                'fas tw-mr-1',
                _vm.generatingBusinessCard ? 'fa-spinner-third fa-spin' : 'fa-scroll'
              ]}),_vm._v(" Visitekaartje genereren (betalend) ")]):_vm._e()],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}}),(_vm.pdfUrl)?_c('iframe',{staticClass:"tw-my-4 tw-w-full tw-h-full tw-min-h-[468px]",attrs:{"id":"pdf_frame","type":"application/pdf","src":_vm.pdfUrl}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }