<template>
  <div>
    <PageHeader :title="product.name" :back-route="{ name: 'Shop' }" />

    <div v-if="loading" class="tw-absolute tw-h-10 tw-top-1/2 tw-left-1/2">
      <i
        class="fal fa-spinner-third fa-spin tw-text-3xl"
      />
    </div>

    <div v-show="!loading" class="tw-my-8 tw-pb-16">
      <h2>Genereer een nieuw visitekaartje</h2>

      <div v-if="!product.is_business_card" class="tw-text-error">
        Dit product is geen visitekaartje.
      </div>

      <div class="tw-grid md:tw-grid-cols-2 tw-gap-4">
        <FormulateForm
          #default="{ hasErrors }"
          v-model="values"
          name="businessCard"
          invalid-message="Gelieve de verplichte velden correct in te vullen."
        >
          <FormulateInput
            type="select"
            name="languages"
            label="Taal"
            placeholder="Selecteer taal"
            :options="USER_LANG_OPTIONS"
            value="nl"
            validation="required"
          />
          <FormulateInput
            v-if="contactNumber"
            type="radio"
            name="phone_number"
            label="Selecteer contactnummer"
            :options="contactNumber.options"
            :value="contactNumber.value"
          />
          <FormulateInput
            v-if="officeOptions.length"
            type="checkbox"
            name="offices"
            label="Selecteer kantoren"
            :options="officeOptions"
          />

          <FormulateErrors />

          <div class="tw-flex tw-flex-wrap tw-gap-2">
            <FormulateInput
              type="submit"
              title="Voorbeeld"
              :disabled="generatingPreview || generatingBusinessCard"
              :input-class="['tw-w-full']"
              outer-class="tw-m-0 tw-flex-grow"
              @click="submit(true, hasErrors)"
            >
              <i
                :class="[
                  'fas tw-mr-1',
                  generatingPreview ? 'fa-spinner-third fa-spin' : 'fa-eye'
                ]"
              />
              Voorbeeld
            </FormulateInput>
            <FormulateInput
              v-if="businessCardPreviewed"
              type="submit"
              title="Maak visitekaartje"
              :disabled="generatingPreview || generatingBusinessCard"
              :input-class="['tw-bg-primary tw-w-full']"
              outer-class="tw-m-0 tw-flex-grow"
              @click="submit(false, hasErrors)"
            >
              <i
                :class="[
                  'fas tw-mr-1',
                  generatingBusinessCard ? 'fa-spinner-third fa-spin' : 'fa-scroll'
                ]"
              />
              Visitekaartje genereren (betalend)
            </FormulateInput>
          </div>
        </FormulateForm>
        <iframe
          v-if="pdfUrl"
          id="pdf_frame"
          type="application/pdf"
          :src="pdfUrl"
          class="tw-my-4 tw-w-full tw-h-full tw-min-h-[468px]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { errorModal } from '@/modalMessages'
import { USER_LANG_OPTIONS, poll } from '@/utils/helpers'

import PageHeader from '@/components/PageHeader.vue'

import { createV2Document, pollV2Gutenborg } from '@/services/gutenborgService'
import { getProductDetails, getCollaboratorPrintData, storeGeneratedBusinessCard } from '@/services/shop'
import { createLog } from '@/services/apiService'

export default {
  name: 'ShopBusinessCard',
  components: {
    PageHeader
  },
  props: {
    productId: {
      type: [Number, String],
      required: true
    }
  },
  constants: {
    USER_LANG_OPTIONS
  },
  data () {
    return {
      loading: false,
      values: {},
      product: { name: '' },
      collaborator: {},
      generatingPreview: false,
      businessCardPreviewed: false,
      generatingBusinessCard: false,
      pdfUrl: null
    }
  },
  computed: {
    officeOptions () {
      return (this.collaborator?.offices || [])
        .map(({ name, id, reference }) => {
          return { label: `${reference} ${name}`, value: id }
        })
    },
    contactNumber () {
      const { mobile, phone } = this.collaborator
      if (!(mobile || phone)) return false

      const options = {}
      if (mobile) options.mobile = `GSM (${mobile})`
      if (phone) options.phone = `Telefoon (${phone})`

      const value = mobile ? 'mobile' : 'phone'
      return { options, value }
    }
  },
  created () {
    this.fetchProductDetails()
  },
  methods: {
    async fetchProductDetails () {
      try {
        this.loading = true
        const response = await getProductDetails(this.productId)
        const { collaborator, ...product } = response.data
        this.product = product
        this.collaborator = collaborator
        this.loading = false
        return response
      } catch (error) {
        errorModal('Kan productdetails niet ophalen, probeer het opnieuw.')
      }
    },
    async submit (preview, hasErrors) {
      // The function doesn't wait for the validations since this happens on @click
      if (hasErrors) return // If validation errors are there, return.

      try {
        preview ? this.generatingPreview = true : this.generatingBusinessCard = true

        const { phone_number, ...values } = this.values
        const response = await getCollaboratorPrintData(this.collaborator.id, {
          phone_number: phone_number || 'mobile', ...values // If no mobile or phone exists, default should be mobile, in this case, empty string will be shown on the business card.
        })

        const printPayload = {
          preview,
          type: 'card',
          template: 4, // Hardcoded for business cards
          snit: true, // Always true,
          lang1: this.values.languages,
          data: response.data
        }
        const generate = await createV2Document(printPayload)

        const { url } = await poll(generate?.data?.job_id, pollV2Gutenborg, 1000)
        this.pdfUrl = url

        // If preview, turn the previewed flag on, else store the generated card.
        if (preview) this.businessCardPreviewed = true
        else {
          await storeGeneratedBusinessCard(this.productId, { url })
          const payload = {
            action_id: 12
          }
          await createLog(payload)
        }
        return url
      } catch (error) {
        console.error(error)
        errorModal('Er is iets misgegaan bij het genereren van je visitekaartje, probeer het opnieuw')
      } finally {
        preview ? this.generatingPreview = false : this.generatingBusinessCard = false
      }
    }
  }
}
</script>
